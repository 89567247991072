@charset 'utf-8';

@import 'settings';
@import 'foundation';

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-text-alignment;

@import 'scss/variables';

// Normalize
@import 'sass/normalize/import-now';

@import 'scss/print-reset';
@import 'scss/pagination';
@import 'scss/page';
@import 'scss/reformat';
@import 'scss/utilities';
body{
    padding: 0;
}
@page{
    size: A4;
    margin: 0;
}
body, html{
    width: 210mm;
    height: 297mm;
}
.cell{
    display: block;
    float: left;
}
.grid-x{
    display: block;
    &:after{
        content: "";
        display: table;
        clear: both;
        width: 100%;
    }
}
hr{
    margin: 1rem 0;
    overflow: hidden;
    page-break-after: avoid;
}

.logo{
    @include xy-cell(full, true, map_get($grid-margin-gutters, large), padding, right left, null, false);
    a{
        margin-bottom: map_get($grid-margin-gutters, small);
        width: 200px;
        display: block;
    }
}
.mainContent{
    .content{
        margin: 0 auto;
        width: 100%;
    }
}
.slick-slide{
    display: none;
    img{
        margin: 0 auto;
        width: 500px;
    }
    &.slick-active{
        display: block;
        margin: 0 auto;
    }
}
.slick-track{
    width: 100% !important;
}

.productItem{
    .button{
        @extend .no-print;
    }
}
.table-scroll{
    overflow: hidden;
    table{

    }
}
table{
    max-width: 100%;
}
.no-print{
    display: none !important;
}
iframe{
    @extend .no-print;
}