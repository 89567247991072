/*
Layout settings
---------------
*/
$baseFontFamily: $body-font-family;
$body-background: $white;
$content-background: $white;
$wrapper-shadow: 0 0 10px rgba($dark-gray, 0.5); // false to disable shadow around layout components, setting false will remove max-width on .wrapper too
$footer-background: #fff;
$base-transition-type: ease;
$base-transition-duration: 300ms;


$z-indexes: (
    main: (),
    topMenu: (),
    header: (),
    modal: (),
    products: (
        attributes,
    )
);

/*
Basic typography
----------------
*/
$smaller-font-size: (
    rem-calc(map-get($breakpoints, medium)): rem-calc(12),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(14),
);
$normal-font-size: (
    rem-calc(map-get($breakpoints, medium)): rem-calc(12),
    rem-calc(map-get($breakpoints, large)): rem-calc(13),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(14),
);
$medium-font-size: (
    rem-calc(map-get($breakpoints, medium)): rem-calc(12),
    rem-calc(map-get($breakpoints, large)): rem-calc(13),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(14),
);
$big-font-size: (
        rem-calc(map-get($breakpoints, medium)): rem-calc(15),
        rem-calc(map-get($breakpoints, large)): rem-calc(17),
        rem-calc(map-get($breakpoints, xlarge)): rem-calc(19),
);
$header1-font-size: (
    rem-calc(map-get($breakpoints, medium)): rem-calc(22),
    rem-calc(map-get($breakpoints, large)): rem-calc(26),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(32),
);
$header2-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(18),
    rem-calc(map-get($breakpoints, medium)): rem-calc(20),
    rem-calc(map-get($breakpoints, large)): rem-calc(22),
);
$header3-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(16),
    rem-calc(map-get($breakpoints, medium)): rem-calc(18),
    rem-calc(map-get($breakpoints, large)): rem-calc(20),
);
$header1-font-weight: 500;
$header1-color: #1c1617;
$header2-font-weight: 500;
$header2-color: #1c1617;
$header3-font-weight: 500;
$header3-color: #1c1617;
$header2-recommend-font-weight: 500;
$header2-recommend-border-height: 3px;
$header2-recommend-border-color: #48b5bf;
$header2-recommend-border-bottom: $header2-recommend-border-height solid $header2-recommend-border-color;
$header2-recommend-triangle-size: rem-calc(16);
$header2-recommend-triangle-color: $header2-recommend-border-color;
$header2-recommend-triangle-background: $white;
$header2-recommend-color: $black;

$header3-font-size: (
    rem-calc(map-get($breakpoints, medium)): rem-calc(18),
    rem-calc(map-get($breakpoints, large)): rem-calc(20),
);

$printerColor: $header2-color;
$faceBookColor: #3b5998;

/*
Forms
-----
*/
$fbutton-svg-size: rem-calc(35);
$fbutton-svg-margin: rem-calc(-20 0 -15 0);

/*
Top menu
--------
*/

$menuType: "slideVertical"; //slideHoritontal, slideVertical
$tm-hasTransition: false;

$tm-font-weight: 500;
$tm-base-height: rem-calc(50);


// slideHoritontal
//$tm-offset: (
//    rem-calc(map-get($breakpoints, small)): rem-calc(0 20 0 10),
//    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(0 0 0 10),
//);

// slideVertical
$tm-offset: (
    rem-calc(map-get($breakpoints, small)): rem-calc(10 0 10 0),
    rem-calc(map-get($breakpoints, large)): rem-calc(0 20 0 10),
    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(0 0 0 10),
);

$tm-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(13),
    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(14),
);
$tm-links-padding: (
    rem-calc(map-get($breakpoints, small)): rem-calc(8 13),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(10),
    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(5 15)
);
$tm-links-background-hover: map_get($foundation-palette, primary);
$tm-links-color-hover: map_get($foundation-palette, secondary);
$tm-background: rgba(#ffd200, 0.6);
$tm-background-slide-vertical: $white;
$tm-border-color: rgba($dark-gray, 0.2);
$tm-border-top: 1px solid $tm-border-color;
$tm-height:(
    rem-calc(map-get($breakpoints, small)): rem-calc(40),
    rem-calc(map-get($breakpoints, large)): $tm-base-height
);
$tm-margin-top: (
    rem-calc(map-get($breakpoints, small)): rem-calc(-40),
    rem-calc(map-get($breakpoints, large)): -$tm-base-height
);
$tm-height-negative: getMapMulti($tm-height, -1);
$tm-offcanvas-button-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(12),
    rem-calc(map-get($breakpoints, medium)): rem-calc(14)
);
$tm-offcanvas-icon-width: (
    rem-calc(map-get($breakpoints, small)): rem-calc(40),
    rem-calc(map-get($breakpoints, medium)): rem-calc(45)
);
$tm-offcanvas-icon-height: (
    rem-calc(map-get($breakpoints, small)): rem-calc(30),
    rem-calc(map-get($breakpoints, medium)): rem-calc(35)
);
$tm-offcanvas-button-background: map_get($foundation-palette, secondary);
$tm-offcanvas-button-color: $white;

// in sidebar
$tm-font-weight-in-sidebar: 200;
$tm-divider-in-sidebar: 1px solid $tm-border-color;
$tm-links-sidebar-margins: (
    rem-calc(map-get($breakpoints, small)): rem-calc(0 5)
);

/*
Header
------
*/
$h-base-background-color: #000;
$h-background-image: url("../img/header-img.jpg") no-repeat $h-base-background-color;
$h-background-size: cover;
$h-buttons-background: $black;
$h-buttons-hover-background: map-get($foundation-palette, secondary);
$h-buttons-hover-color: $h-buttons-background;
$h-buttons-icon-color: map_get($foundation-palette, secondary);
$h-buttons-icon-padding: rem-calc(5);
$h-buttons-text-padding: rem-calc(10);
$h-buttons-font-color: map_get($foundation-palette, secondary);
$h-buttons-radius: rem-calc(5);
$h-button-hamburger-font-weight: 500;

$h-buttons-sizes: (
    rem-calc(map-get($breakpoints, small)): rem-calc(40),
    rem-calc(map-get($breakpoints, large)): rem-calc(50),
);
$h-buttons-textLabel-color: map_get($foundation-palette, secondary);
$h-links-top-offset: (
    rem-calc(map-get($breakpoints, large)): rem-calc(5),
);
$h-registerLink-right-offset: (
    rem-calc(map-get($breakpoints, large)): rem-calc(20),
    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(30)
);
$h-loginLink-right-offset: (
    rem-calc(map-get($breakpoints, large)): rem-calc(120),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(130)
);
$h-textLabel-color: map_get($foundation-palette, primary);
$h-links-color: $h-textLabel-color;
$h-search-and-basket-icon-size: (
    rem-calc(map-get($breakpoints, large)): rem-calc(36)
);
$h-inputs-background: rgba($white, 0.5);
$h-inputs-background-hover: rgba($white, 0.8);
$h-inputs-icon-color: mix($h-inputs-background, map_get($foundation-palette, primary), 80%);
$h-search-input-widths: (
    rem-calc(map-get($breakpoints, large)): rem-calc(140),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(260),
    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(320),
);
$h-search-and-basket-button-font-weight: 500;
$h-search-and-basket-button-padding: (
    rem-calc(map-get($breakpoints, large)): rem-calc(8)
);
$h-search-button-margin: (
        rem-calc(map-get($breakpoints, large)): rem-calc(0 5),
        rem-calc(map-get($breakpoints, xlarge)): rem-calc(0 6)
);
$h-logo-widths: (
    rem-calc(map-get($breakpoints, small)): rem-calc(150),
    rem-calc(map-get($breakpoints, medium)): rem-calc(230),
    rem-calc(map-get($breakpoints, large)): rem-calc(350),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(400)
);
$h-height: (
    rem-calc(map-get($breakpoints, small)): rem-calc(70),
    rem-calc(map-get($breakpoints, medium)): rem-calc(96),
    rem-calc(map-get($breakpoints, large)): rem-calc(176)
);
$h-box-shadow: 0 5px 5px -5px rgba($dark-gray, 0.5);
$h-badge-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(9),
    rem-calc(map-get($breakpoints, medium)): rem-calc(11),
);
$h-badge-line-height: (
    rem-calc(map-get($breakpoints, small)): rem-calc(15),
    rem-calc(map-get($breakpoints, medium)): rem-calc(17),
);
$h-badge-font-weight: bold;
$h-badge-position-right: rem-calc(-8);
$h-badge-position-left-on-desktop: rem-calc(30);
$h-badge-position-top: rem-calc(-8);

/*
Breadcrumbs
-----------
*/
$bc-border-top: 1px solid $light-gray;
$bc-border-bottom: $bc-border-top;
$bc-breakpoint-to-show-border-top: medium up;
$bc-text-decoration: underline;
$bc-text-decoration-hover: none;
$bc-item-font-weight: 200;
$bc-last-item-font-weight: 600;
/*
Sidebar & e-shop menu
---------------------
 */
$sb-header-color: map-get($foundation-palette, secondary);
$sb-close-button-size: rem-calc(30);
$sb-close-button-top: rem-calc(-7);
$sb-close-button-right: rem-calc(0);
$sb-close-button-color: map-get($foundation-palette, secondary);

$sb-first-level-menu-item-padding: (
    rem-calc(map-get($breakpoints, small)): rem-calc(8 0 8 18),
    rem-calc(map-get($breakpoints, large)): rem-calc(3 0 3 18),
);
$sb-first-level-menu-item-arrow-size: rem-calc(16);
$sb-first-level-menu-item-arrow-color:map_get($foundation-palette, secondary);
$sb-first-level-menu-item-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(13),
);
$sb-second-level-menu-item-padding: $sb-first-level-menu-item-padding;
$sb-second-level-menu-item-arrow-size: $sb-first-level-menu-item-arrow-size;
$sb-second-level-menu-item-arrow-color: $light-gray;
$sb-second-level-menu-item-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(13),
);

/*
Footer
------
 */
$f-benefits-background: #ffbe1d;
$f-benefits-icon-color: $white;
$f-benefits-icon-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(60),
    rem-calc(map-get($breakpoints, large)): rem-calc(70),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(80),
);
$fi-base-font-size: (
    rem-calc(map-get($breakpoints, medium)): rem-calc(12),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(13),
);
$fi-h3-font-size: $header3-font-size;
$fi-h3-font-weight: 500;
$fi-elements-padding: (
    rem-calc(map-get($breakpoints, small)): rem-calc(3 0 3 20),
);
$fi-menu-item-arrow-size: rem-calc(16);
$fi-menu-item-arrow-color: $light-gray;
$fi-h3-padding: $fi-elements-padding;
$fi-h3-arrow-size: rem-calc(14);
$fi-h3-arrow-rotate: rotate(90deg);
$fi-h3-arrow-color: map_get($foundation-palette, secondary);
$fi-background: mix($light-gray, $white, 40%);
$fi-social-icons-size: rem-calc(45);

$f-margin-bottom: (
    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(40),
);

/*
Swiper
------
*/
$swiper-bullets-bottom-offset: rem-calc(0);
$swiper-bullet-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(8),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(16),
);
$swiper-bullet-margin: (
        rem-calc(map-get($breakpoints, small)): rem-calc(0 4),
        rem-calc(map-get($breakpoints, xlarge)): rem-calc(0 8),
);
$swiper-bullet-background-dark: $light-gray;
$swiper-bullet-background-light: $white;
$swiper-bullet-active-background: $black;
$swiper-bullet-active-background-light: $white;
$swiper-bullet-active-background-dark: $light-gray;

$swiper-controls-arrow-size: rem-calc(30);
$swiper-controls-arrow-color:#000;
$swiper-controls-arrow-color-hover:#cc4b37;

/*
Product List
------------
*/
$pl-border: true; //false for disabling borders around products
$pl-border-width: 1px;
$pl-border-style: solid $light-gray;
$pl-header-color: $black;
$pl-price-color: map-get($foundation-palette, red);
$pl-price-font-weight: 800;

/*
Cateogies List
--------------
*/
$cl-border-style: $pl-border-style;
$cl-border-width: $pl-border-width;
$cl-header-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(14),
    rem-calc(map-get($breakpoints, large)): rem-calc(16),
);
$cl-header-font-weight: 500;
$cl-header-color: map_get($foundation-palette, primary);
$cl-header-arrow-size: rem-calc(20);
$cl-header-arrow-color: map_get($foundation-palette, secondary);

/*
Product detail
--------------
 */
$gallery-thumbs-height: (
    rem-calc(map-get($breakpoints, small)): rem-calc(70),
    rem-calc(map-get($breakpoints, medium)): rem-calc(100),
    rem-calc(map-get($breakpoints, large)): rem-calc(100),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(80),
);
$gallery-top-height: (
    rem-calc(map-get($breakpoints, small)): rem-calc(280),
    rem-calc(400): rem-calc(400),
    rem-calc(map-get($breakpoints, medium)): rem-calc(520),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(360),
);
$gallery-top-border: true;
$gallery-top-border-style: 1px solid $light-gray;
$gallery-thumbs-border: true;
$gallery-thumbs-border-style: 1px solid $light-gray;
$gallery-thumbs-padding: rem-calc(5);
$gallery-thumbs-inactive-opacity: 0.4;

$p-info-table-font-weight: 500;

$p-benefits-icon-color: map_get($foundation-palette, success);
$p-benefits-icon-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(40),
    rem-calc(map-get($breakpoints, large)): rem-calc(55)
);

$t-border-color: $light-gray;
$t-border-widht: 1px;
$t-border: $t-border-widht solid $t-border-color;
$t-border-width: $t-border-widht $t-border-widht 0 $t-border-widht;
$t-background: mix($light-gray, $white, 40%);
$t-background-active: $white;
$t-font-size: $smaller-font-size;
$t-item-padding: (
    rem-calc(map-get($breakpoints, large)): rem-calc(15 8),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(15 12),
    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(15 18),
);
$t-item-margin: (
    rem-calc(map-get($breakpoints, large)): rem-calc(0 5 0 0),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(0 10 0 0),
);

$acc-title-font-size: $medium-font-size;
$acc-title-font-weight: 500;
$acc-title-padding: (
    rem-calc(map-get($breakpoints, small)): rem-calc(10),
    rem-calc(map-get($breakpoints, medium)): rem-calc(15),
);
$acc-title-plusminus-color: #de0025;
$acc-title-plusminus-width: rem-calc(50);
$acc-title-plusminus-size: rem-calc(20);
$acc-title-plusminus-border: 1px solid $light-gray;

$attributes-palette: (
    primary: map_get($foundation-palette, primary),
    blue: #0078aa,
    orange: #f46e0b,
    green: #5caa00,
    red: #de0025,
);

/*
Checkout
--------
 */
$step-background: mix(white, $light-gray, 50%);
$step-background-hover: mix($step-background, $black, 98%);
$step-border-color: $light-gray;
$step-border-width: 1px;
$step-border-bottom: $step-border-width solid $step-border-color;
$step-height: (
    rem-calc(map-get($breakpoints, small)): rem-calc(90),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(110),
);
$step-border-right: $step-border-width solid $step-border-color;
$step-font-weight: 500;
$step-counter-font-weight: 800;
$step-counter-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(30),
    rem-calc(map-get($breakpoints, medium)): rem-calc(40),
);
$step-font-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(12),
    rem-calc(map-get($breakpoints, large)): rem-calc(11),
    rem-calc(map-get($breakpoints, xlarge)): rem-calc(13),
    rem-calc(map-get($breakpoints, xxlarge)): rem-calc(14),
);
$step-counter-background: $white;
$step-counter-border: 1px solid $light-gray;
$step-active-background: false;
$step-active-color: false;
$step-active-counter-background: #4bbdc7;
$step-active-counter-border: 1px solid #4bbdc7;
$step-active-counter-color: $white;
$step-active-triangle-size: rem-calc(15);

$pcounter-size: (
    rem-calc(map-get($breakpoints, small)): rem-calc(40),
);
$pcounter-border-radius: 0;
$pcounter-input-width: rem-calc(50);
$pcounter-sort-background: mix($light-gray, white, 50%);
$pcounter-sort-border: 1px solid $medium-gray;
$pcounter-arrow-size: rem-calc(18);
$pcounter-arrow-color: map-get($foundation-palette, secondary);
$pcounter-arrow-rotate: 90deg;
$pcounter-arrow-rotate-up: -$pcounter-arrow-rotate;

$p-close-button-background: mix($light-gray, $white, 20%);
$p-close-button-color: map-get($foundation-palette, secondary);
$p-close-button-size: rem-calc(28);


$ch-product-line-border: 1px solid $light-gray;
$ch-prices-border: $ch-product-line-border;
$ch-prices-background: mix($light-gray, white, 40%);
$ch-discount-code-border: $ch-product-line-border;
$ch-anchor-color: mix(map_get($foundation-palette, secondary), $black, 80%);
